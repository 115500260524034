$defaultFont: "Montserrat", sans-serif;
$bodyColorText: rgb(73, 68, 64);
$lansingBlue: #62B5E5;
$lansingBlueDark: #003B5C;
$lansingGreen: #64A70B;
$lansingGold: #F1B434;
$lansingOrange: #B86125;
$lansingMidGreen: #00B388;
$lansingRed: #AF272F;
$lansingBurgundy: #7C2529;
$lansingTeal: #40C1AC;


$customLightBlue: rgba(0, 118, 206, .5);