@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.builder-page-width {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    font-size: 16px;
    font-family: var(--sans-heading, Gotham-Medium, Montserrat);
}

.builderiotext,
.builder-text {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: var(--text-primary, #555555);
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: var(--sans-heading, Gotham-Medium, Montserrat);
        font-style: normal;
        margin: 0;
    }

    p {
        strong {
            font-weight: 900;
            color: var(--text-primary, #555555);
        }
    }
}

.builderiotext,
.builder-text {
    h1 {
        font-size: 96px;
    }

    @media (max-width: 768px) {
        &.fontSize100>h1 {
            font-size: 96px;
        }

        &.fontSize90>h1 {
            font-size: 96px * 0.9;
        }

        &.fontSize80>h2 {
            font-size: 96px * 0.8;
        }

        &.fontSize70>h1 {
            font-size: 96px * 0.7;
        }

        &.fontSize60>h1 {
            font-size: 96px * 0.6;
        }

        &.fontSize50>h1 {
            font-size: 96px * 0.5;
        }
    }
}

.builderiotext h1,
.builder-text h1 {
    font-weight: 400;
    line-height: 116.7%;
    letter-spacing: -1.5px;

    em {
        font-family: var(--sans-body, DroidSerif-Italic);
    }
}

.builderiotext,
.builder-text {
    h2 {
        font-size: 60px;
    }

    @media (max-width: 768px) {
        &.fontSize100>h2 {
            font-size: 60px;
        }

        &.fontSize90>h2 {
            font-size: 60px * 0.9;
        }

        &.fontSize80>h2 {
            font-size: 60px * 0.8;
        }

        &.fontSize70>h2 {
            font-size: 60px * 0.7;
        }

        &.fontSize60>h2 {
            font-size: 60px * 0.6;
        }

        &.fontSize50>h2 {
            font-size: 60px * 0.5;
        }
    }
}

.builderiotext h2,
.builder-text h2 {
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.5px;

    em {
        font-family: var(--sans-body, DroidSerif-Italic);
    }
}

.builderiotext,
.builder-text {
    h3 {
        font-size: 48px;
    }

    @media (max-width: 768px) {
        &.fontSize100>h3 {
            font-size: 48px;
        }

        &.fontSize90>h3 {
            font-size: 48px * 0.9;
        }

        &.fontSize80>h3 {
            font-size: 48px * 0.8;
        }

        &.fontSize70>h3 {
            font-size: 48px * 0.7;
        }

        &.fontSize60>h3 {
            font-size: 48px * 0.6;
        }

        &.fontSize50>h3 {
            font-size: 48px * 0.5;
        }
    }
}

.builderiotext h3,
.builder-text h3 {
    line-height: 116.7%;
    letter-spacing: -0.7px;
    font-weight: 400;

    strong {
        font-weight: 700;
        color: var(--text-primary, #555555);
    }

    em {
        font-family: var(--sans-body, DroidSerif-Italic);
    }
}

.builderiotext,
.builder-text {
    h4 {
        font-size: 34px;
    }

    @media (max-width: 768px) {
        &.fontSize100>h4 {
            font-size: 34px;
        }

        &.fontSize90>h4 {
            font-size: 34px * 0.9;
        }

        &.fontSize80>h4 {
            font-size: 34px * 0.8;
        }

        &.fontSize70>h4 {
            font-size: 34px * 0.7;
        }

        &.fontSize60>h4 {
            font-size: 34px * 0.6;
        }

        &.fontSize50>h4 {
            font-size: 34px * 0.5;
        }
    }
}

.builderiotext h4,
.builder-text h4 {
    font-weight: 400;

    strong {
        font-weight: 700;
        color: var(--text-primary, #555555);
    }

    em {
        font-family: var(--sans-body, DroidSerif-Italic);
    }

    line-height: 123.5%;
    letter-spacing: -0.7px;

}

.builderiotext,
.builder-text {
    h5 {
        font-size: 24px;
    }

    @media (max-width: 768px) {
        &.fontSize100>h5 {
            font-size: 24px;
        }

        &.fontSize90>h5 {
            font-size: 24px * 0.9;
        }

        &.fontSize80>h5 {
            font-size: 24px * 0.8;
        }

        &.fontSize70>h5 {
            font-size: 24px * 0.7;
        }

        &.fontSize60>h5 {
            font-size: 24px * 0.6;
        }

        &.fontSize50>h5 {
            font-size: 24px * 0.5;
        }
    }
}

.builderiotext h5,
.builder-text h5 {
    font-weight: 400;
    line-height: 133.4%;
    letter-spacing: -0.7px;

    strong {
        font-weight: 500;
        color: var(--text-primary, #555555);
    }

    em {
        font-family: var(--sans-body, DroidSerif-Italic);
    }

}

.builderiotext,
.builder-text {
    h6 {
        font-size: 20px;
    }

    @media (max-width: 768px) {
        &.fontSize100>h6 {
            font-size: 20px;
        }

        &.fontSize90>h6 {
            font-size: 20px * 0.9;
        }

        &.fontSize80>h6 {
            font-size: 20px * 0.8;
        }

        &.fontSize70>h6 {
            font-size: 20px * 0.7;
        }

        &.fontSize60>h6 {
            font-size: 20px * 0.6;
        }

        &.fontSize50>h6 {
            font-size: 20px * 0.5;
        }
    }
}

.builderiotext h6,
.builder-text h6 {
    font-family: var(--sans-heading, Gotham-Medium);

    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.15px;

    em {
        font-family: var(--sans-body, DroidSerif-Italic);
    }
}

.builderiotext,
.builder-text {
    p {
        font-size: 16px;
    }

    @media (max-width: 768px) {
        &.fontSize100>p {
            font-size: 16px;
        }

        &.fontSize90>p {
            font-size: 16px * 0.9;
        }

        &.fontSize80>p {
            font-size: 16px * 0.8;
        }

        &.fontSize70>p {
            font-size: 16px * 0.7;
        }

        &.fontSize60>p {
            font-size: 16px * 0.6;
        }

        &.fontSize50>p {
            font-size: 16px * 0.5;
        } 
    }
}

.builderiotext p,
.builderiotext p strong,
.builderiotext p em,
.builder-text p,
.builder-text p strong,
.builder-text p em {
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: var(--sans-body, Gotham-Medium);
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.15px;
    font-family: var(--sans-heading, Gotham-Medium, Montserrat);
    color: var(--text-primary, #555555);
}

@media (max-width: 992px) {

    .builder-page-width {
        padding: 0 !important;
        margin-top: -55px !important;
    }

    // .homepageFeatureZoneContainer {
    //     margin-top: -80px;
    // }
}

@media only screen and (min-width: 640px) and (max-width: 1200px) {
    .builder-page-width {
        padding: 0px !important;
        width: 97%;
        margin-top: -40px !important;
    }

    // .homepageFeatureZoneContainer {
    //     margin-top: -80px;

    // }
    .carouselContainerSmall {
        align-items: center;

        .linkContainer {
            padding: 0 !important;
            margin: 90px 0 0 0 !important;
            gap: 20px
        }
    }
}

@media screen and (max-width: 680px) {
    .aa-Panel {
      top: 0px !important;
    }
}

.ais-NavMenu-span {
    word-wrap: break-word;
    white-space: normal;
}

