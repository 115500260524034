.ais-Hits {
  width: 100%;

  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: start;
    margin: 0;
    padding: 0;
    gap: 15px 0;
    @include respond-to(small) {
      gap: 20px 1%;
    }
    @include respond-to(large) {
      gap: 20px 1%;
      justify-content: start;
    }
    @include respond-to(xlarge) {
      gap: 25px 1%;
      justify-content: start;
    }
    li {
      display: flex;
      width: 50%;
      min-height: 400px;

      @include respond-to(small) {
        width: 49%;
      }
      @include respond-to(large) {
        width: 32%;
      }
      @include respond-to(xlarge) {
        width: 24%;
      }

      div {
        width: 100%;
      }
    }
  }
}

.ais-HitsPerPage-select {
  border: 1px solid #c4c8d8;
  padding: .3rem 2rem .3rem .3rem;
  background: white;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0px 20px;
}

.ais-Pagination {
  background-color: white;
}

.ais-Pagination-list {
  margin: 40px auto;
  padding: 0;
  text-align: center;
  display: flex;
  list-style: none;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;

  .ais-Pagination-item {
    background: white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #c5c5c5;
    border-radius: 100%;
    font-size: 14px;

    &--selected {
      background: $lansingBlue;

      a {
        color: white;
        border: 0px solid $lansingBlue;
      }
    }
  }

  .ais-Pagination-link {
    text-decoration: none;
    color: black;
    display: block;
  }
}

.sidebarMenu {
  padding: 0 15px;

  strong {
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  b {
    font-size: 18px;
    padding: 10px 0;
    display: block;
  }

  .minus-icon,
  .plus-icon {
    font-weight: normal;
    font-size: 24px;
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 20px;

    .ais-RefinementList-count {
      float: right;
    }
  }

  .ais-RefinementList-checkbox {
    float: left;
    margin-left: 0;
    margin-right: 5px;
  }

  .ais-RefinementList-labelText {
    color: $lansingBlueDark;
    width: 77%;
    display: inline-block;
  }

  .ais-RefinementList-label.category-label {
    display: flex;
    justify-content: space-between;
    color: $lansingBlueDark;
    cursor: pointer;

    div {
      display: flex;
      align-items: flex-start;

      .ais-RefinementList-labelText {
        width: auto;
      }

      img {
        margin-left: 10px;
      }
    }
  }

  .ais-RefinementList-count,
  .ais-HierarchicalMenu-count {
    font-size: 12px;
    font-weight: 500 !important;
    color: #707372;
    background-color: #d9f0ff;
    padding: .1rem .4rem;
    border-radius: 8px;
    height: 15px;
  }

  .ais-CurrentRefinements-list {
    display: flex !important;
    flex-wrap: wrap;
  }

  .ais-CurrentRefinements-item {
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;

    .ais-CurrentRefinements-label {
      display: none;
    }
  }

  .ais-RefinementList-list {
    margin-bottom: 30px !important;
  }

  .ais-CurrentRefinements-categoryLabel {
    color: black;
  }

  .ais-CurrentRefinements-category {
    border: 1px solid #808080;
    background: #eee;
    font-size: 80%;
    padding: 5px 8px;
    margin-right: 5px;
  }

  .ais-CurrentRefinements-delete {
    background: transparent;
    border: 0;
    position: relative;
    top: 1px;
    right: -4px;
    cursor: pointer;
    opacity: 0.6;
  }

  .ais-RefinementList-showMore.ais-RefinementList-showMore--disabled,
  .ais-ClearRefinements-button.ais-ClearRefinements-button--disabled {
    display: none;
  }

  .ais-RefinementList-showMore,
  .ais-ClearRefinements-button {
    border: 0;
    background: transparent;
    font-family: $defaultFont;
    color: $lansingBlue;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 10px;
    display: block;
    clear: both;
  }

  .ais-ToggleRefinement {
    margin-bottom: 25px;
    border-bottom: 1px solid #eee;
    padding-bottom: 25px;
  }

  input[type="search"] {
    height: 50px;
    padding: 0.3rem 1rem 0.3rem 2.5rem;
    width: 100%;
    position: relative;
    background-color: #fff;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    font-family: $defaultFont;
    margin-bottom: 30px;
    font-size: 16px;
    @include search-icon;

    &::placeholder {
      color: #a5aed1;
      opacity: 1;
    }
  }

  .ais-ToggleRefinement-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    font-weight: bold;
    margin-top: 1rem;
  }
}

// sidebar end


.colorSelector {
  input {
    display: none;
  }

  .swatch-wrapper.l-hmargin--small {
    width: 43%;
    float: left;
    margin-bottom: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px;
    margin: 3px;
    color: $lansingBlueDark;
    cursor: pointer;

    span.swatch {
      width: 24px;
      height: 24px;
      border: 1px solid #e5e5e5;
      display: block;
    }

  }
}

.swatch-wrapper.l-hmargin--small.selected {
  background-color: #eeeeee !important;
}

.MuiDrawer-paper {
  a {
    color: #323232;
    text-decoration: none;
  }
}

ul.productCategoryMenu {
  list-style: none;
  padding: 0;

  li {

    // border-bottom: 1px solid #4f4f4f;
    &:hover {
      background-color: #ecf6fc !important;
      border-radius: 8px !important;
    }

    ul:not(.ais-HierarchicalMenu-list) {
      padding-left: 10px;
      background-color: white !important;

      li:not(.ais-HierarchicalMenu-item) {
        border-bottom: 0 !important;
        background-color: white !important;
      }
    }
  }



  a {
    color: #323232;
    font-weight: 600;
    text-decoration: none;
    line-height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


.productCategoryMenu.ais-HierarchicalMenu-list {
  .ais-HierarchicalMenu-item {
    border-bottom: 1px solid #000;
    padding: 10px 0;
    border-radius: 0 !important;

    @include respond-to(xlarge) {
      border: 0;
      padding: inherit;
      border-radius: 10px !important;
    }

    li {
      border: 0;
    }
  }
}


.gg-chevron {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px
}

.gg-chevron::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px;
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-item--parent>div>.ais-HierarchicalMenu-link .gg-chevron {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-item--parent>div>.ais-HierarchicalMenu-link .gg-chevron::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.ais-ToggleRefinement-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  font-size: inherit;
  width: 2.5em;
  height: 1.5em;
  box-sizing: content-box;
  border: 0;
  background: #dedede;
  border-radius: 1em;
  vertical-align: text-bottom;
  margin: 0 0 0 5px;
  color: inherit;
}

.ais-ToggleRefinement-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translate(0, -50%);
  box-sizing: border-box;
  width: 1em;
  height: 1em;
  margin: 0 0.05em;
  border: 0;
  border-radius: 100%;
  background: gray;
}

.ais-ToggleRefinement-checkbox:checked::before {
  left: 1.1em;
  background: gray;

}

.ais-ToggleRefinement-checkbox:checked {
  background: $lansingBlue;
}

.ais-Highlight-highlighted {
  background: #ffc168;
}

// Menu
ul.productCategoryMenu li:hover {
  background-color: transparent !important;
  border-radius: 0px !important;

  @include respond-to(large) {
    background-color: #ecf6fc !important;
    border-radius: 8px !important;
  }
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--selected>div>.ais-HierarchicalMenu-link .ais-HierarchicalMenu-label {
  color: #62B5E5;
}

#ais-NavMenu-categories .ais-HierarchicalMenu>.ais-HierarchicalMenu-list>.ais-HierarchicalMenu-item--selected>div:first-child {
  @include respond-to(large) {
    background-color: #ECF6FC;
  }

  border-radius: 10px;
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent {
  @include respond-to(large) {
    background-color: #ECF6FC;
  }

  border-radius: 10px;
}





#ais-NavMenu-categories .viewAll {
  padding-top: 0px !important;
}





#view-all-clickable {
  display: none;
}

#view-all-clickable.show {
  display: block;
  margin: 15px 0;

  @include respond-to(large) {
    margin: inherit;
  }
}

.mobileMenuItems {

  .ais-NavMenu-header,
  .ais-NavMenu-subheader {
    display: none;
  }
}

.productCategoryMenu {
  position: relative;

  li {
    width: 300px;
    height: auto;

    @include respond-to(xlarge) {
      height: 38px;
    }

    ul {
      position: relative;

      @include respond-to(xlarge) {
        position: absolute;
        left: 300px;
        width: 300px;
        top: 20px;
      }

      left: 0;
      top: 0px;
      width: 100%;

      li {
        height: auto;

        ul {
          position: relative;
          left: 0px;
          top: 0px;
        }
      }
    }
  }

}

.ais-HierarchicalMenu-list--child {
  text-indent: 0px;
  padding-left: 20px;

  @include respond-to(large) {
    text-indent: 0px;
  }

}

.ais-NavMenu-header {
  color: black;
  font-size: 16px !important;
  font-weight: bold;
  padding-bottom: 8px;
  width: 100%;

  @include respond-to(medium) {
    width: 300px;
  }

  display: inline-block;
}

.ais-NavMenu-subheader {
  display: inline-block;
  left: 0px;
  font-weight: bold;
  position: relative;
  top: 0px;

  @include respond-to(medium) {
    left: 15px;
    top: 20px;
  }
}

.ais-Highlight-nonHighlighted {
	white-space: normal;
}


.MuiMenu-list {
  li {
    display: block;
  }
}

.menuFullWidth {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    width: 650px;
    padding: 0 !important;
  }
}

.ais-HierarchicalMenu-link.viewAll {
  span {
    @include view-all-link;
  }
}

#view-all-clickable {
  @include view-all-link;
}
.aa-List {
	flex-direction: column;
  display: flex;
  @include respond-to(medium) {
    flex-direction: row;
  }    
}
#autocomplete-0-products-list {
	flex-direction: column;
}

.aa-Source[data-autocomplete-source-id="recentSearchesPlugin"]{
  ul{
    flex-direction: row !important;
    flex-wrap: wrap;
    gap: 10px;  
    li{
      border: 1px solid #ccc;
      padding: 4px 10px !important;
      min-height: 24px;
    }

  }
}
.aa-Source[data-autocomplete-source-id="products"]{
  ul{
    display: flex;
    flex-direction: column !important;

  }
}
// search header
.aa-Panel {
  width: 93% !important;
  margin-left: 0px;
  font-size: 80%;
  z-index: 10;
  
  @include respond-to(xlarge) {
    width: 740px !important;
    margin-left: -310px;
    height: auto;
  }  

  .aa-SourceHeader {
    span {
      color: $lansingBlue;
      font-size: 100%;
    }
  }

  section[data-autocomplete-source-id="querySuggestionsPlugin"] ul {
    display: flex;

    .aa-ItemActions {
      display: none;
    }
  }

  .popularProductHolder {
    .aa-ItemIcon {
      width: 60px;
      height: 60px;

      img {
        min-width: 100%;
        min-height: 100%;
      }
    }
  }

  .see-all-products {
    color: $lansingBlue;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: $defaultFont;
    cursor: pointer;
  }
}


.aa-Panel--scrollable {
  max-height: 100% !important;
  padding: 12px 20px;
}

.pgpFacets .ais-SearchBox-submit,
.pgpFacets .ais-SearchBox-reset {
  display: none;
}

// VIEW ALL
@media screen and (min-width: 1200px) {
  #ais-NavMenu-categories>.ais-HierarchicalMenu>.ais-HierarchicalMenu-list>.ais-HierarchicalMenu-item>div>.viewAll {
    display: none;
  }

  #ais-NavMenu-categories .ais-HierarchicalMenu-item:not(.ais-HierarchicalMenu-item--parent)>div>.viewAll {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .ais-HierarchicalMenu-link.viewAll {
    display: none;
  }

  .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent {
    div:first-child {
      .viewAll {
        display: block;
      }

      .viewAll span {
        display: block;
        font-weight: 600 !important;
        font-style: normal !important;
        text-decoration: none !important;
        color: #323232 !important;
        padding-left: 20px !important;
      }
    }

    .ais-HierarchicalMenu-list--child {
      .viewAll {
        display: none !important;
      }

    }

    .ais-HierarchicalMenu-list--child {
      ul {
        .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent {
          div:first-child {
            .viewAll {
              display: block !important;
            }

            .viewAll span {
              display: block !important;
              font-weight: 600 !important;
              font-style: normal !important;
              text-decoration: none !important;
              color: #323232 !important;
              padding-left: 0px !important;
            }

          }
        }

      }
    }



  }

}