.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    ul {
        li:not(.ais-HierarchicalMenu-item) {
            background-color: white;

            a {
                color: $bodyColorText;
                text-decoration: none;
                font-weight: 500;
                margin: 5px 15px;
            }

        }
    }
}

.floatingHeader {
    margin-top: 0 !important;
}

.notAtPageTop .floatingHeader {
    display: flex;
    flex-direction: column-reverse;
    top: 0;


    @include respond-to(medium) {
        display: block !important;

    }
}

.notAtPageTop .headerWarningMessage {
    display: none;

    @include respond-to(medium) {
        display: block !important;
    }
}

.headerCardText {
    color: rgb(73, 68, 64);
    font-size: 11px;
    text-transform: none;
    position: relative;
    top: 2px;
    line-height: 1;
    text-align: center;
}

.headerImpersonatingMessage {
    background: #b86125;
    color: white;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.mobileHelpItem {
    background-color: #003B5C;
    color: white;
    text-align: right;

    div {
        display: flex;
        justify-content: flex-end;
    }

    img,
    p,
    svg {
        display: inline;
        vertical-align: top;
        justify-content: end;
    }

    p {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.mobileFullWidthDrawer {
    width: 100%;
}

.homepageFeatureZoneContainer .headerOnlyBanner {
    display: none !important;
}

@media (max-width: 900px) {

    .notAtPageTop .infoGlobalHeader {
        display: none;
    }

    .notAtPageTop .headerImpersonatingMessage {
        display: none;
    }

    .notAtPageTop .fullHeaderBanner {
        display: flex;
        flex-direction: column;

        .mainHeaderHolder {
            order: 1;
        }

        .branchBannerContainer {
            order: 2;
        }
    }

    .headerImpersonatingMessage span,
    .headerImpersonatingMessage a {
        display: inline-block;
    }

    .infoGlobalHeader .headerOnlyBanner {
        flex-direction: column;
        align-items: start;
        line-height: normal;
        padding-bottom: 4px;
        font-size: 12px;
    }

    .infoGlobalHeader .headerOnlyBanner>* {
        white-space: normal;
        display: block;
        font-weight: 500;
        line-height: 14px;
    }

    .infoGlobalHeader .headerOnlyBanner>span>svg {
        display: none;
    }

    .infoGlobalHeader .headerOnlyBanner>a {
        margin-left: 0 !important;
    }
}