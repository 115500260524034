.branchBannerContainer {
  font-size: 85%;
  font-weight: 500;
  border-top: 2px transparent !important;
  border-left: 2px solid #eaeaea !important;
  border-right: 2px solid #eaeaea !important;
  border-bottom: 2px solid #eaeaea !important;
  position: relative;


  a {
    color: $lansingBlue;
    font-weight: 700;
  }
}

@media screen and (min-width: 1200px) {
  .branchBannerContainer {
    z-index: 1500;
  }
}

.branchBannerContainer .branchBannerInfo {
  color: #54afe2;
  font-weight: 700;
  font-size: 13px;

  @include respond-to(small) {
    font-size: 14px;
  }
}

.branchBannerInfoWorkTime {
  font-size: 11px;

  @include respond-to(small) {
    font-size: 13px;
  }
}

.homepageFeatureZoneContainer .mapSearchInput,
.homepageFeatureZoneContainer .mapSearchButton,
.homepageFeatureZoneContainer .mapUseCurrentLocation {
  display: none !important;
}

.branchDialogMapContainer {
  display: none;

  @include respond-to(xlarge) {
    display: initial;
  }
}

.mapholderModal {
  height: 100%;
}

.warehouseModal {
  .mapholderModal {
    height: 380px;
  }

  .validationMessage {
    color: $lansingRed;
    margin-top: 5px;
    margin-bottom: 0;
    display: none;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .validationMessage.visible {
    display: block;
    opacity: 1;
  }

  @include respond-to(large) {
    .validationMessage {
      margin-top: 0 !important;
    }
  }
}

.branchDialogTableContainer {
  .MuiDataGrid-row {
    cursor: pointer;

    &:hover {
      button:not(:disabled) {
        background: $lansingBlue !important;
        color: white !important;
      }
    }
  }

  @include respond-to(small) {
    .MuiDataGrid-row {
      cursor: inherit;

      &:hover {
        button:not(:disabled) {
          background: $lansingBlue !important;
          color: white !important;
        }
      }
    }

  }

}