.productCard {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 #e3e5ec;
  padding: 20px 10px 40px 10px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include respond-to(small) {
    padding: 20px 20px 40px 20px;
}

  .blueButtonOutline {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}

.bottomCardHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.image-wrapper {
  text-align: center;
  max-width: 100%;
  width: 325px;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-to(small) {
    min-height: 300px;
    max-height: 300px;
  }
}