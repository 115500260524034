.lansingDarkColor {
    color: $lansingBlueDark
}

.m-0 {
    margin: 0 !important;
}

.me-1 {
    margin-right: 1rem !important;
}

.my-1 {
    margin: 1rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.text-center {
    text-align: center;
}

.MuiDataGrid-cell {
    display: flex;
    align-items: center;
}

.flex-center {
    @include center-flex;
}

.flex-start {
    @include start-flex
}

.w200 {
    width: 200px;
}

.noUnderlineLink {
    text-decoration: none;
}

.MuiDialog-root {
    z-index: 1501 !important;
}

.MuiDialog-root+.MuiPopover-root.MuiMenu-root.MuiModal-root {
    z-index: 1502 !important;
}

.MuiTabs-root {
    button {
        text-transform: capitalize;
        color: $bodyColorText
    }
}

.welcome-banner {
    height: auto;
    margin-bottom: 0px;
    background-image: none;
    flex-direction: column-reverse !important;

    @include respond-to(medium) {
        height: 530px;
        margin-bottom: 100px;
        flex-direction: row !important;
        background-image: url("/src/img/welcome-banner.jpeg");
    }
}


.welcome-banner-left {
    background-color: rgba(0, 118, 206, .5);
    color: white;
    padding: 50px;
    font-size: 1.2em;

    p {
        line-height: 1.5;
        font-weight: 600;
    }
}

// .page-margin {
//     margin-top: 160px;

//     @include respond-to(small) {
//         margin-top: 170px;
//     }

//     @include respond-to(xlarge) {
//         margin-top: 0px;
//     }

// }

.page-title {
    background: $lansingBlue;
    margin-bottom: 50px;
    padding: 0 20px;
    position: relative;

    h1 {
        background: none;
        color: #fff;
        font-weight: 600;
        display: block;
        margin-top: 0;
        line-height: 1;
        font-size: 2.2rem;
        padding: 40px 0 35px;
    }
}

.page-title::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-width: 0 15px 15px;
    border-style: dashed dashed solid;
    border-color: transparent transparent #f8f8f8;
}

.featured-categories-grid {
    .category {
        height: 140px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        background-size: cover;
    }

    .category-title {
        background: rgba(0, 60, 105, .8);
        height: 45px;
        margin-top: auto;

        a {
            display: flex;
            justify-content: space-between;
            text-decoration: none;
            height: 100%;

            span {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                text-transform: uppercase;
                color: white;
                font-size: 16px;
                font-weight: 600;

                &:nth-child(2) {
                    white-space: nowrap;
                }
            }
        }
    }

}

.homepageImpactPrograms {
    background-image: url('/src/img/homepage-impact-programs.jpg');
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0px;
    gap: 100px;
    position: relative;
    width: 1200px;
    height: 350px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}

.homepageImpactProgramsOverlay {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    height: 100%;
}

.homepageImpactProgramsLeft {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

.homepageImpactProgramsRight {
    width: 50%;
    display: inline-block;
    vertical-align: top;

}

.homepageImpactProgramsLogo {
    width: 300px;
    height: 101.66px;
    margin-top: 40px;
    margin-left: 40px;
}

.homepageImpactProgramsText {
    margin-top: 20px;
    margin-left: 40px;
    width: 350px;
    height: 60px;
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
    align-items: flex-end;
    color: #FFFFFF;
}

.homepageImpactProgramsButton {
    margin-top: 30px !important;
    margin-left: 40px !important;
    border: 1px solid white !important;
    color: white !important;
    border-radius: 5px;
    width: 300px;
    padding: 10px;
}

.homepageImpactProgramsRight {
    width: 552px;
    height: 350px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    gap: 12px;
}

@media only screen and (max-width: 1026px) {
    .homepageImpactProgramsRight {
        display: none;
    }
}


.homepageImpactProgramsRightItem {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

.homepageImpactProgramsPlus {
    width: 20px;
    height: 20px;
}

.homepageImpactProgramsPlusText {
    font-family: 'Gotham-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    color: #FFFFFF;
    margin-left: 10px;
}

.itemCountText {
    font-size: 0.8rem;
    color: $lansingBlueDark;
}

.tabSpecificationList {
    display: flex;
    gap: 10px;
    padding-bottom: 20px;
}

.tabAboutList {
    line-height: 34px;
}

.flatProductSpecificationHeader {
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;
    font-weight: 700;
    font-size: 16px;
}

.autocompleteColorSelector {
    @include start-flex;
    padding-left: 15px;
    margin-bottom: 10px;
    cursor: pointer;
}

.bottomCardHolderText {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 14px;
    }
}


.MuiTabs-indicator {
    background-color: $lansingBlue !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: $lansingBlue !important;
}

section[data-autocomplete-source-id="categories.lvl1"],
section[data-autocomplete-source-id="manufacturername"] {
    ul {
        display: flex;
    }
}

.aa-SubmitIcon {
    color: #62b5e5 !important;
}


.sidebarIconLink {
    span {
        display: flex;
        gap: 10px;
        font-weight: 500;

        img {
            width: 20px;
            height: 20px;
        }
    }
}


.productDetailsSecondaryContainer {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
}

.productDetailsSecondaryImage {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    border: 1px solid white;

    img {
        width: 100%;
        max-height: 100%;
    }

    &:hover {
        border: 1px solid #eaeaea;
    }
}


@media screen and (min-width: 1200px) and (max-width:1620px) {
    .mainMenuButtonsResponsive {
        width: 125px;
        padding: 10px !important;
        font-size: 90% !important;
    }
}


.homepageFeatureZoneContainer {
    .grid-1 {
        position: relative;
        border-radius: 15px;
        overflow: hidden;
    }

    .carouselContainer {
        height: 100%;
        background-color: #163b56;
        background-size: 35%;
        background-image: url("/src/img/featureZoneCarouselBackground.png");
        background-repeat: no-repeat;
        background-position: bottom right;
        padding: 0px;
        box-sizing: border-box;
        color: white;
        border-radius: 15px;
        min-height: 290px;

        @include respond-to(medium) {
            background-size: 45%;
            min-height: 600px;
        }

        @media (min-width: 600px) and (max-width: 899px) {
            min-height: 450px;
        }

        h1 {
            font-size: 22px;

            @include respond-to(small) {
                font-size: 46px;
            }
        }

        h2 {
            font-size: 16px;

            @include respond-to(small) {
                font-size: 26px;
            }
        }

    }

    .slide {
        height: 100%;
        margin: 30px;
    }

    .slideBuilderWidget {
        height: 100%;
    }

    .carouselContainer button{
        z-index: 999999;
    }

    .carouselContainerSmall {
        background-color: $lansingTeal;
        border-radius: 15px;
        overflow: hidden;
        height: 100%;
        text-align: center;
        padding-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @include respond-to(large) {
            flex-direction: column;
        }

        @include respond-to(xlarge) {
            padding-bottom: 0px;
        }

        img {
            max-width: 80%;
            width: 40%;

            @include respond-to(large) {
                width: 100%;
            }
        }

        .linkContainer {
            display: flex;
            flex-direction: column;
            justify-content: end;
            padding-bottom: 40px;
        }

    }

    .carouselContainer {
        div {
            &:first-child {
                height: 90% !important;
            }
        }
    }

    .mapHolder {
        overflow: hidden;
        border-radius: 15px !important;
        width: 100%;
        height: 350px;

        @include respond-to(small) {
            height: 600px;
        }

    }

    .branch {
        position: absolute;
        z-index: 1;
        width: 97%;
        height: 96%;
        padding: 10px 25px;
        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(44, 126, 172, 1) 0%, rgba(98, 181, 229, 0.7) 100%);
        border-radius: 15px;
        color: white;

        @include respond-to(small) {
            width: 50%;
            height: 98%;
        }

        @include respond-to(large) {
            width: 50%;
            height: 98%;
        }

        @include respond-to(xlarge) {
            width: 50%;
            height: 97.5%;
        }
    }

    .branchText {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        h1 {
            font-size: 22px;

            @include respond-to(small) {
                font-size: 28px;
            }
        }

        .branchTextNormal {
            font-weight: 400;
        }
    }

    h2 {
        margin-top: 0;
        font-weight: normal;



    }

    .carouselContainerSmall {
        color: white;

        h2 {
            margin-top: 20px;
            margin-bottom: 0;
            min-height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.3rem;
            padding: 0 5px;
        }

        .animation {
            align-self: center;
        }
    }

    .grid-1 {
        order: 1;
    }

    .grid-2 {
        order: 3;

    }

    .grid-3 {
        order: 2;
        margin-bottom: 10px;
    }

    @include respond-to(xlarge) {
        .grid-1 {
            order: 1;
        }

        .grid-2 {
            order: 2;
        }

        .grid-3 {
            order: 3;
            margin-bottom: 0px;
        }

    }
}

.gallerySlideshow {
    margin-bottom: 50px;

    .gallerySlides {
        img {
            width: 100%;
            height: 100%;
            border: 1px solid #ebebeb;
            box-shadow: 0 0 5px #ccc;
            box-sizing: content-box;

        }
    }
}

.dialogFullsizeImage {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 50px);
}


.fullsizeTablet {
    width: 100%;
}

.measureContainer {
    white-space: wrap;
    display: flex;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;

}

//   responsive

.hide-xs {
    display: block;

    @include respond-to(medium) {
        display: none;
    }
}

.hide-on-mob {
    display: none;

    @include respond-to(medium) {
        display: block;
    }
}


.responsiveMarker-hide {
    position: fixed;
    top: 0px;
    height: 15px;
    z-index: 99999 !important;
    width: 100%;
    font-size: 10px;

    @include respond-to(small) {
        background: lime;
    }

    @include respond-to(medium) {
        background: blue;
    }

    @include respond-to(large) {
        background: pink;
    }

    @include respond-to(xlarge) {
        background: gray;
    }
}

.handCursor {
    cursor: pointer;
}

// CUSTOMER PROGRAMS

.cp_backgroundImage {
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 320px;
    background-position-x: 50%;
    background-position-y: 50%;

    h1 {
        color: white;
        font-size: 46px;
        text-align: left;
        width: 100%;
        font-weight: 600;
    }
}

.customerProgramsTable {
    border-collapse: collapse;
    max-width: 350px;

    th {
        text-align: center;
        background-color: $lansingBlue;
        color: white;
        border: 1px solid #ddd;
        padding: 10px;
    }

    td {
        text-align: center;
        border: 1px solid #ddd;
        padding: 10px;
    }
}

.aa-Autocomplete {
    .aa-DetachedSearchButtonPlaceholder {
        text-transform: uppercase !important;
    }

}

.billPay {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    gap: 20px;
    padding: 30px 0;
}

.carouselContainerSmallLogged {
    background-color: #40C1AC;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white
}

.view-now-btn {
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-transform: none;
    border-radius: 4px;
    font-weight: 500;
    background: #ffffff;
    color: black;
    text-decoration: none;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 20px;
}

.mb-5perc {
    margin-bottom: 5%;
}

.text-white {
    color: white;
}

@media screen and (max-width: 1199px) {
    .mb-5perc {
        margin-bottom: 20px;
    }

    .mb-5percLast {
        margin-bottom: 50px !important;
    }

    .billPay {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

#root {
    overflow-x: hidden !important;
}

// MINI CARD
.cartHeader {
    margin: 0 -15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    padding: 0 15px 2px 15px;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
}
.cartCloseButton {
     min-width: 25px !important;
     height : 25px;
     margin: -4px -10px 0 0 !important;
}
.cardHolder{
    max-height: 360px;
    position: relative;
    margin: 2px -15px;
    padding: 0 15px;
    overflow-y: scroll;
    background-color: #fff;
}
.cartItemsBox{
    margin-top: 16px !important;
}
.cartFooter {
    margin: 0 -15px -15px;
    padding: 15px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    background-color: #eaeaea;
    box-shadow: 0 0 8px 0 gray;

    .blueButton {
        margin-top: 10px;
        padding: 12px 30px;
        border: 0;
    }
}

.itemCartContainer {
    display: flex;
    align-items: top;
    margin-bottom: 15px;
}

.itemCartImage {
    width: 90px;
    height: 90px;
    overflow: hidden;
    min-width: 90px;
    margin-right: 10px;

    img {
        width: 100%;
    }
}

.itemCartDescHeader {
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.itemCartDescName{
    overflow: hidden;
    max-height: 46px;
    font-size: 18px;
    line-height: 22px;
    font-family: "DroidSerif-Regular", Times New Roman, serif;
    padding-right: 36px;
    color: #000;
    text-decoration: none;    
}
.itemCartDesc {
    h3 {
        font-size: 16px;
        line-height: 22px;
        font-family: 'DroidSerif700';
        font-weight: 100;
        margin: 0 !important;
    }

    table {
        tr {          

            .color-gray {
                color: #494440
            }

            td {
                padding: 1px 0;
                font-size: 13px;
                border: 0;

                .color-green {
                    color: #008000
                }

                .cart-item-special {
                    color: red
                }

                &:nth-child(2) {
                    font-weight: 500;
                }

                input {
                    padding: 0;
                    text-align: center;
                    width: 55px;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }


                input[type=number] {
                    -moz-appearance: textfield;
                }

                fieldset {
                    border: 0;
                }

            }
        }
    }

    .w90 {
        width: 140px;
    }
}
.fontSize14{
    font-size: 14px;
}
.itemCardClose{
    position: absolute;
    top: 0px;
    right: -5px;
}
.zIndex2000{
    z-index: 2000 !important;
}
// MINI CARD END



.secondaryUserBanner{
    text-align: center;
    background: #62b5e5;
    color: white;
    padding: 10px;
}

.mobHeightBox {
    height: 250px !important;
    @include respond-to(medium) {
        height: 42% !important;
    }
}
.homepageFeatureZoneContainer .grid-2 {
	order: 2;
    @include respond-to(medium) {
        order: 3;
    }    
}
 
 