@font-face {
    font-family: 'DroidSerif700';
    src: url('../fonts/DroidSerif700.ttf') format('truetype');
}

@font-face {
    font-family: 'DroidSerif-Regular';
    src: url('../fonts/DroidSerif-Regular-webfont.ttf') format('truetype');
}

@font-face {
    font-family: 'DroidSerif-BoldItalic';
    src: url('../fonts/DroidSerif-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'DroidSerif-Italic';
    src: url('../fonts/DroidSerif-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'georgia';
    src: url('../fonts/georgia.ttf') format('truetype');
}

@font-face {
    font-family: 'georgiab';
    src: url('../fonts/georgiab.ttf') format('truetype');
}

@font-face {
    font-family: 'georgiai';
    src: url('../fonts/georgiai.ttf') format('truetype');
}

@font-face {
    font-family: 'georgiaz';
    src: url('../fonts/georgiaz.ttf') format('truetype');
}

@font-face {
    font-family: 'Gotham Light';
    src: url('../fonts/Gotham Light.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham-Black';
    src: url('../fonts/Gotham-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham-Bold';
    src: url('../fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham-Book';
    src: url('../fonts/Gotham-Book.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham-Light';
    src: url('../fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham-Medium';
    src: url('../fonts/Gotham-Medium.otf') format('opentype');
}
