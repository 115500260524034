.footer {
    background-color: #707372;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include respond-to(medium) {
        flex-direction: row;
    }

    .socialNetworks {
        list-style-type: none;
        display: flex;
        gap: 10px;
        padding: 0;
        margin: 20px 0;
    }

    .footer-main {
        @include spaceBetween-flex;
        padding: 50px;
        color: white;
        text-transform: uppercase;
        font-size: 85%;

        @include respond-to(medium) {
            width: 70% !important;
        }


        p {
            font-weight: 600;
        }

        a {
            color: white;
            text-decoration: none;
        }
    }

    .footer-newsletter {
        background: $lansingBlue;
        width: 100%;

        @include respond-to(medium) {
            width: 450px;
        }

        img {
            height: 98px;
            width: 140px;

            @include respond-to(medium) {
                width: 216px;
                height: 168px;
            }
        }

        text-align: right;
        padding: 50px 0 0 0;
    }
}