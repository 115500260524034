@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tienne:wght@400;700;900&display=swap");
@import 'fonts.css';
body {
  font-family: "Montserrat", sans-serif;
  margin: 0 auto;
  color: rgb(73, 68, 64);
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;
}

.customerProgramsFont {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
}

.customerProgramsFontBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 26px;
}

.customerProgramsFontBlack {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: normal;
  line-height: 26px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded ul li:not(.ais-HierarchicalMenu-item) {
  background-color: white;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded ul li:not(.ais-HierarchicalMenu-item) a {
  color: rgb(73, 68, 64);
  text-decoration: none;
  font-weight: 500;
  margin: 5px 15px;
}

.floatingHeader {
  margin-top: 0 !important;
}

.notAtPageTop .floatingHeader {
  display: flex;
  flex-direction: column-reverse;
  top: 0;
}
@media (min-width: 900px) {
  .notAtPageTop .floatingHeader {
    display: block !important;
  }
}

.notAtPageTop .headerWarningMessage {
  display: none;
}
@media (min-width: 900px) {
  .notAtPageTop .headerWarningMessage {
    display: block !important;
  }
}

.headerCardText {
  color: rgb(73, 68, 64);
  font-size: 11px;
  text-transform: none;
  position: relative;
  top: 2px;
  line-height: 1;
  text-align: center;
}

.headerImpersonatingMessage {
  background: #b86125;
  color: white;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.mobileHelpItem {
  background-color: #003B5C;
  color: white;
  text-align: right;
}
.mobileHelpItem div {
  display: flex;
  justify-content: flex-end;
}
.mobileHelpItem img,
.mobileHelpItem p,
.mobileHelpItem svg {
  display: inline;
  vertical-align: top;
  justify-content: end;
}
.mobileHelpItem p {
  margin-left: 5px;
  margin-right: 5px;
}

.mobileFullWidthDrawer {
  width: 100%;
}

.homepageFeatureZoneContainer .headerOnlyBanner {
  display: none !important;
}

@media (max-width: 900px) {
  .notAtPageTop .infoGlobalHeader {
    display: none;
  }
  .notAtPageTop .headerImpersonatingMessage {
    display: none;
  }
  .notAtPageTop .fullHeaderBanner {
    display: flex;
    flex-direction: column;
  }
  .notAtPageTop .fullHeaderBanner .mainHeaderHolder {
    order: 1;
  }
  .notAtPageTop .fullHeaderBanner .branchBannerContainer {
    order: 2;
  }
  .headerImpersonatingMessage span,
  .headerImpersonatingMessage a {
    display: inline-block;
  }
  .infoGlobalHeader .headerOnlyBanner {
    flex-direction: column;
    align-items: start;
    line-height: normal;
    padding-bottom: 4px;
    font-size: 12px;
  }
  .infoGlobalHeader .headerOnlyBanner > * {
    white-space: normal;
    display: block;
    font-weight: 500;
    line-height: 14px;
  }
  .infoGlobalHeader .headerOnlyBanner > span > svg {
    display: none;
  }
  .infoGlobalHeader .headerOnlyBanner > a {
    margin-left: 0 !important;
  }
}
.footer {
  background-color: #707372;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 900px) {
  .footer {
    flex-direction: row;
  }
}
.footer .socialNetworks {
  list-style-type: none;
  display: flex;
  gap: 10px;
  padding: 0;
  margin: 20px 0;
}
.footer .footer-main {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  color: white;
  text-transform: uppercase;
  font-size: 85%;
}
@media (min-width: 900px) {
  .footer .footer-main {
    width: 70% !important;
  }
}
.footer .footer-main p {
  font-weight: 600;
}
.footer .footer-main a {
  color: white;
  text-decoration: none;
}
.footer .footer-newsletter {
  background: #62B5E5;
  width: 100%;
  text-align: right;
  padding: 50px 0 0 0;
}
@media (min-width: 900px) {
  .footer .footer-newsletter {
    width: 450px;
  }
}
.footer .footer-newsletter img {
  height: 98px;
  width: 140px;
}
@media (min-width: 900px) {
  .footer .footer-newsletter img {
    width: 216px;
    height: 168px;
  }
}

.ais-Hits {
  width: 100%;
}
.ais-Hits ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: start;
  margin: 0;
  padding: 0;
  gap: 15px 0;
}
@media (min-width: 600px) {
  .ais-Hits ol {
    gap: 20px 1%;
  }
}
@media (min-width: 1024px) {
  .ais-Hits ol {
    gap: 20px 1%;
    justify-content: start;
  }
}
@media (min-width: 1200px) {
  .ais-Hits ol {
    gap: 25px 1%;
    justify-content: start;
  }
}
.ais-Hits ol li {
  display: flex;
  width: 50%;
  min-height: 400px;
}
@media (min-width: 600px) {
  .ais-Hits ol li {
    width: 49%;
  }
}
@media (min-width: 1024px) {
  .ais-Hits ol li {
    width: 32%;
  }
}
@media (min-width: 1200px) {
  .ais-Hits ol li {
    width: 24%;
  }
}
.ais-Hits ol li div {
  width: 100%;
}

.ais-HitsPerPage-select {
  border: 1px solid #c4c8d8;
  padding: 0.3rem 2rem 0.3rem 0.3rem;
  background: white;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0px 20px;
}

.ais-Pagination {
  background-color: white;
}

.ais-Pagination-list {
  margin: 40px auto;
  padding: 0;
  text-align: center;
  display: flex;
  list-style: none;
  gap: 10px;
  justify-content: center;
  padding: 5px 0px;
}
.ais-Pagination-list .ais-Pagination-item {
  background: white;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #c5c5c5;
  border-radius: 100%;
  font-size: 14px;
}
.ais-Pagination-list .ais-Pagination-item--selected {
  background: #62B5E5;
}
.ais-Pagination-list .ais-Pagination-item--selected a {
  color: white;
  border: 0px solid #62B5E5;
}
.ais-Pagination-list .ais-Pagination-link {
  text-decoration: none;
  color: black;
  display: block;
}

.sidebarMenu {
  padding: 0 15px;
}
.sidebarMenu strong {
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.sidebarMenu b {
  font-size: 18px;
  padding: 10px 0;
  display: block;
}
.sidebarMenu .minus-icon,
.sidebarMenu .plus-icon {
  font-weight: normal;
  font-size: 24px;
  cursor: pointer;
}
.sidebarMenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebarMenu li {
  margin-bottom: 20px;
}
.sidebarMenu li .ais-RefinementList-count {
  float: right;
}
.sidebarMenu .ais-RefinementList-checkbox {
  float: left;
  margin-left: 0;
  margin-right: 5px;
}
.sidebarMenu .ais-RefinementList-labelText {
  color: #003B5C;
  width: 77%;
  display: inline-block;
}
.sidebarMenu .ais-RefinementList-label.category-label {
  display: flex;
  justify-content: space-between;
  color: #003B5C;
  cursor: pointer;
}
.sidebarMenu .ais-RefinementList-label.category-label div {
  display: flex;
  align-items: flex-start;
}
.sidebarMenu .ais-RefinementList-label.category-label div .ais-RefinementList-labelText {
  width: auto;
}
.sidebarMenu .ais-RefinementList-label.category-label div img {
  margin-left: 10px;
}
.sidebarMenu .ais-RefinementList-count,
.sidebarMenu .ais-HierarchicalMenu-count {
  font-size: 12px;
  font-weight: 500 !important;
  color: #707372;
  background-color: #d9f0ff;
  padding: 0.1rem 0.4rem;
  border-radius: 8px;
  height: 15px;
}
.sidebarMenu .ais-CurrentRefinements-list {
  display: flex !important;
  flex-wrap: wrap;
}
.sidebarMenu .ais-CurrentRefinements-item {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
}
.sidebarMenu .ais-CurrentRefinements-item .ais-CurrentRefinements-label {
  display: none;
}
.sidebarMenu .ais-RefinementList-list {
  margin-bottom: 30px !important;
}
.sidebarMenu .ais-CurrentRefinements-categoryLabel {
  color: black;
}
.sidebarMenu .ais-CurrentRefinements-category {
  border: 1px solid #808080;
  background: #eee;
  font-size: 80%;
  padding: 5px 8px;
  margin-right: 5px;
}
.sidebarMenu .ais-CurrentRefinements-delete {
  background: transparent;
  border: 0;
  position: relative;
  top: 1px;
  right: -4px;
  cursor: pointer;
  opacity: 0.6;
}
.sidebarMenu .ais-RefinementList-showMore.ais-RefinementList-showMore--disabled,
.sidebarMenu .ais-ClearRefinements-button.ais-ClearRefinements-button--disabled {
  display: none;
}
.sidebarMenu .ais-RefinementList-showMore,
.sidebarMenu .ais-ClearRefinements-button {
  border: 0;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  color: #62B5E5;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 30px;
  margin-top: 10px;
  display: block;
  clear: both;
}
.sidebarMenu .ais-ToggleRefinement {
  margin-bottom: 25px;
  border-bottom: 1px solid #eee;
  padding-bottom: 25px;
}
.sidebarMenu input[type=search] {
  height: 50px;
  padding: 0.3rem 1rem 0.3rem 2.5rem;
  width: 100%;
  position: relative;
  background-color: #fff;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px;
  font-size: 16px;
  background-image: url("/src/img/search.svg");
  background-position: 8px;
  background-size: 20px;
  background-repeat: no-repeat;
}
.sidebarMenu input[type=search]::-moz-placeholder {
  color: #a5aed1;
  opacity: 1;
}
.sidebarMenu input[type=search]::placeholder {
  color: #a5aed1;
  opacity: 1;
}
.sidebarMenu .ais-ToggleRefinement-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  font-weight: bold;
  margin-top: 1rem;
}

.colorSelector input {
  display: none;
}
.colorSelector .swatch-wrapper.l-hmargin--small {
  width: 43%;
  float: left;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px;
  margin: 3px;
  color: #003B5C;
  cursor: pointer;
}
.colorSelector .swatch-wrapper.l-hmargin--small span.swatch {
  width: 24px;
  height: 24px;
  border: 1px solid #e5e5e5;
  display: block;
}

.swatch-wrapper.l-hmargin--small.selected {
  background-color: #eeeeee !important;
}

.MuiDrawer-paper a {
  color: #323232;
  text-decoration: none;
}

ul.productCategoryMenu {
  list-style: none;
  padding: 0;
}
ul.productCategoryMenu li:hover {
  background-color: #ecf6fc !important;
  border-radius: 8px !important;
}
ul.productCategoryMenu li ul:not(.ais-HierarchicalMenu-list) {
  padding-left: 10px;
  background-color: white !important;
}
ul.productCategoryMenu li ul:not(.ais-HierarchicalMenu-list) li:not(.ais-HierarchicalMenu-item) {
  border-bottom: 0 !important;
  background-color: white !important;
}
ul.productCategoryMenu a {
  color: #323232;
  font-weight: 600;
  text-decoration: none;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productCategoryMenu.ais-HierarchicalMenu-list .ais-HierarchicalMenu-item {
  border-bottom: 1px solid #000;
  padding: 10px 0;
  border-radius: 0 !important;
}
@media (min-width: 1200px) {
  .productCategoryMenu.ais-HierarchicalMenu-list .ais-HierarchicalMenu-item {
    border: 0;
    padding: inherit;
    border-radius: 10px !important;
  }
}
.productCategoryMenu.ais-HierarchicalMenu-list .ais-HierarchicalMenu-item li {
  border: 0;
}

.gg-chevron {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-chevron::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg);
  right: 6px;
  top: 4px;
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-item--parent > div > .ais-HierarchicalMenu-link .gg-chevron {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-item--parent > div > .ais-HierarchicalMenu-link .gg-chevron::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px;
}

.ais-ToggleRefinement-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  font-size: inherit;
  width: 2.5em;
  height: 1.5em;
  box-sizing: content-box;
  border: 0;
  background: #dedede;
  border-radius: 1em;
  vertical-align: text-bottom;
  margin: 0 0 0 5px;
  color: inherit;
}

.ais-ToggleRefinement-checkbox::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translate(0, -50%);
  box-sizing: border-box;
  width: 1em;
  height: 1em;
  margin: 0 0.05em;
  border: 0;
  border-radius: 100%;
  background: gray;
}

.ais-ToggleRefinement-checkbox:checked::before {
  left: 1.1em;
  background: gray;
}

.ais-ToggleRefinement-checkbox:checked {
  background: #62B5E5;
}

.ais-Highlight-highlighted {
  background: #ffc168;
}

ul.productCategoryMenu li:hover {
  background-color: transparent !important;
  border-radius: 0px !important;
}
@media (min-width: 1024px) {
  ul.productCategoryMenu li:hover {
    background-color: #ecf6fc !important;
    border-radius: 8px !important;
  }
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--selected > div > .ais-HierarchicalMenu-link .ais-HierarchicalMenu-label {
  color: #62B5E5;
}

#ais-NavMenu-categories .ais-HierarchicalMenu > .ais-HierarchicalMenu-list > .ais-HierarchicalMenu-item--selected > div:first-child {
  border-radius: 10px;
}
@media (min-width: 1024px) {
  #ais-NavMenu-categories .ais-HierarchicalMenu > .ais-HierarchicalMenu-list > .ais-HierarchicalMenu-item--selected > div:first-child {
    background-color: #ECF6FC;
  }
}

#ais-NavMenu-categories .ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent {
  border-radius: 10px;
}
@media (min-width: 1024px) {
  #ais-NavMenu-categories .ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent {
    background-color: #ECF6FC;
  }
}

#ais-NavMenu-categories .viewAll {
  padding-top: 0px !important;
}

#view-all-clickable {
  display: none;
}

#view-all-clickable.show {
  display: block;
  margin: 15px 0;
}
@media (min-width: 1024px) {
  #view-all-clickable.show {
    margin: inherit;
  }
}

.mobileMenuItems .ais-NavMenu-header,
.mobileMenuItems .ais-NavMenu-subheader {
  display: none;
}

.productCategoryMenu {
  position: relative;
}
.productCategoryMenu li {
  width: 300px;
  height: auto;
}
@media (min-width: 1200px) {
  .productCategoryMenu li {
    height: 38px;
  }
}
.productCategoryMenu li ul {
  position: relative;
  left: 0;
  top: 0px;
  width: 100%;
}
@media (min-width: 1200px) {
  .productCategoryMenu li ul {
    position: absolute;
    left: 300px;
    width: 300px;
    top: 20px;
  }
}
.productCategoryMenu li ul li {
  height: auto;
}
.productCategoryMenu li ul li ul {
  position: relative;
  left: 0px;
  top: 0px;
}

.ais-HierarchicalMenu-list--child {
  text-indent: 0px;
  padding-left: 20px;
}
@media (min-width: 1024px) {
  .ais-HierarchicalMenu-list--child {
    text-indent: 0px;
  }
}

.ais-NavMenu-header {
  color: black;
  font-size: 16px !important;
  font-weight: bold;
  padding-bottom: 8px;
  width: 100%;
  display: inline-block;
}
@media (min-width: 900px) {
  .ais-NavMenu-header {
    width: 300px;
  }
}

.ais-NavMenu-subheader {
  display: inline-block;
  left: 0px;
  font-weight: bold;
  position: relative;
  top: 0px;
}
@media (min-width: 900px) {
  .ais-NavMenu-subheader {
    left: 15px;
    top: 20px;
  }
}

.ais-Highlight-nonHighlighted {
  white-space: normal;
}

.MuiMenu-list li {
  display: block;
}

.menuFullWidth .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  width: 650px;
  padding: 0 !important;
}

.ais-HierarchicalMenu-link.viewAll span {
  font-weight: normal !important;
  font-style: italic !important;
  text-decoration: underline !important;
  color: black !important;
}

#view-all-clickable {
  font-weight: normal !important;
  font-style: italic !important;
  text-decoration: underline !important;
  color: black !important;
}

.aa-List {
  flex-direction: column;
  display: flex;
}
@media (min-width: 900px) {
  .aa-List {
    flex-direction: row;
  }
}

#autocomplete-0-products-list {
  flex-direction: column;
}

.aa-Source[data-autocomplete-source-id=recentSearchesPlugin] ul {
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 10px;
}
.aa-Source[data-autocomplete-source-id=recentSearchesPlugin] ul li {
  border: 1px solid #ccc;
  padding: 4px 10px !important;
  min-height: 24px;
}

.aa-Source[data-autocomplete-source-id=products] ul {
  display: flex;
  flex-direction: column !important;
}

.aa-Panel {
  width: 93% !important;
  margin-left: 0px;
  font-size: 80%;
  z-index: 10;
}
@media (min-width: 1200px) {
  .aa-Panel {
    width: 740px !important;
    margin-left: -310px;
    height: auto;
  }
}
.aa-Panel .aa-SourceHeader span {
  color: #62B5E5;
  font-size: 100%;
}
.aa-Panel section[data-autocomplete-source-id=querySuggestionsPlugin] ul {
  display: flex;
}
.aa-Panel section[data-autocomplete-source-id=querySuggestionsPlugin] ul .aa-ItemActions {
  display: none;
}
.aa-Panel .popularProductHolder .aa-ItemIcon {
  width: 60px;
  height: 60px;
}
.aa-Panel .popularProductHolder .aa-ItemIcon img {
  min-width: 100%;
  min-height: 100%;
}
.aa-Panel .see-all-products {
  color: #62B5E5;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
}

.aa-Panel--scrollable {
  max-height: 100% !important;
  padding: 12px 20px;
}

.pgpFacets .ais-SearchBox-submit,
.pgpFacets .ais-SearchBox-reset {
  display: none;
}

@media screen and (min-width: 1200px) {
  #ais-NavMenu-categories > .ais-HierarchicalMenu > .ais-HierarchicalMenu-list > .ais-HierarchicalMenu-item > div > .viewAll {
    display: none;
  }
  #ais-NavMenu-categories .ais-HierarchicalMenu-item:not(.ais-HierarchicalMenu-item--parent) > div > .viewAll {
    display: none;
  }
}
@media screen and (max-width: 1199px) {
  .ais-HierarchicalMenu-link.viewAll {
    display: none;
  }
  .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent div:first-child .viewAll {
    display: block;
  }
  .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent div:first-child .viewAll span {
    display: block;
    font-weight: 600 !important;
    font-style: normal !important;
    text-decoration: none !important;
    color: #323232 !important;
    padding-left: 20px !important;
  }
  .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-list--child .viewAll {
    display: none !important;
  }
  .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-list--child ul .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent div:first-child .viewAll {
    display: block !important;
  }
  .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent .ais-HierarchicalMenu-list--child ul .ais-HierarchicalMenu-item.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent div:first-child .viewAll span {
    display: block !important;
    font-weight: 600 !important;
    font-style: normal !important;
    text-decoration: none !important;
    color: #323232 !important;
    padding-left: 0px !important;
  }
}
a {
  color: #62B5E5;
}

.whiteButton {
  border: 1.75px solid #fff;
  border-radius: 4px !important;
  color: #fff;
  padding: 15px 30px;
  margin-top: 20px;
  display: inline-block !important;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
}
.whiteButton:hover {
  background: white;
  color: #62B5E5;
}

.blueButton {
  border: 1.75px solid #fff;
  border-radius: 4px !important;
  background: #62B5E5;
  color: #fff;
  padding: 15px 30px;
  margin-top: 20px;
  display: inline-block !important;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
}
.blueButton:hover {
  background: white;
  color: #62B5E5;
  border: 1.75px solid #62B5E5;
}

.blueButtonOutline {
  border: 1.75px solid #62B5E5 !important;
  border-radius: 4px !important;
  background: white;
  color: #62B5E5 !important;
  padding: 15px 30px;
  margin-top: 20px;
  display: inline-block !important;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}
.blueButtonOutline:hover {
  background: #62B5E5 !important;
  color: white !important;
  border: 1.75px solid #62B5E5 !important;
}

.linkButtons {
  font-family: "Montserrat";
  color: "red";
}

.mainMenuButtons {
  text-decoration: none;
  color: #494440 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 21px !important;
  border-radius: 0 !important;
}
.mainMenuButtons:hover {
  background-color: #494440 !important;
  color: white !important;
}
.mainMenuButtons:focus {
  background-color: #494440 !important;
  color: white !important;
}

.button-active {
  background: #494440 !important;
  color: white !important;
}

.carouselBackButton {
  position: absolute !important;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 40px;
  min-width: 40px !important;
  border-radius: 30px !important;
  color: white !important;
}
.carouselBackButton:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.carouselBackButton svg {
  position: relative;
  left: 5px;
}

.carouselNextButton {
  position: absolute !important;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 40px;
  min-width: 40px !important;
  border-radius: 30px !important;
  color: white !important;
}
.carouselNextButton:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.carouselNextButton svg {
  position: relative;
}

.branchBannerContainer {
  font-size: 85%;
  font-weight: 500;
  border-top: 2px transparent !important;
  border-left: 2px solid #eaeaea !important;
  border-right: 2px solid #eaeaea !important;
  border-bottom: 2px solid #eaeaea !important;
  position: relative;
}
.branchBannerContainer a {
  color: #62B5E5;
  font-weight: 700;
}

@media screen and (min-width: 1200px) {
  .branchBannerContainer {
    z-index: 1500;
  }
}
.branchBannerContainer .branchBannerInfo {
  color: #54afe2;
  font-weight: 700;
  font-size: 13px;
}
@media (min-width: 600px) {
  .branchBannerContainer .branchBannerInfo {
    font-size: 14px;
  }
}

.branchBannerInfoWorkTime {
  font-size: 11px;
}
@media (min-width: 600px) {
  .branchBannerInfoWorkTime {
    font-size: 13px;
  }
}

.homepageFeatureZoneContainer .mapSearchInput,
.homepageFeatureZoneContainer .mapSearchButton,
.homepageFeatureZoneContainer .mapUseCurrentLocation {
  display: none !important;
}

.branchDialogMapContainer {
  display: none;
}
@media (min-width: 1200px) {
  .branchDialogMapContainer {
    display: initial;
  }
}

.mapholderModal {
  height: 100%;
}

.warehouseModal .mapholderModal {
  height: 380px;
}
.warehouseModal .validationMessage {
  color: #AF272F;
  margin-top: 5px;
  margin-bottom: 0;
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.warehouseModal .validationMessage.visible {
  display: block;
  opacity: 1;
}
@media (min-width: 1024px) {
  .warehouseModal .validationMessage {
    margin-top: 0 !important;
  }
}

.branchDialogTableContainer .MuiDataGrid-row {
  cursor: pointer;
}
.branchDialogTableContainer .MuiDataGrid-row:hover button:not(:disabled) {
  background: #62B5E5 !important;
  color: white !important;
}
@media (min-width: 600px) {
  .branchDialogTableContainer .MuiDataGrid-row {
    cursor: inherit;
  }
  .branchDialogTableContainer .MuiDataGrid-row:hover button:not(:disabled) {
    background: #62B5E5 !important;
    color: white !important;
  }
}

.productCard {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 #e3e5ec;
  padding: 20px 10px 40px 10px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 600px) {
  .productCard {
    padding: 20px 20px 40px 20px;
  }
}
.productCard .blueButtonOutline {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.bottomCardHolder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.bottomCardHolder p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.image-wrapper {
  text-align: center;
  max-width: 100%;
  width: 325px;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 600px) {
  .image-wrapper {
    min-height: 300px;
    max-height: 300px;
  }
}

.lansingDarkColor {
  color: #003B5C;
}

.m-0 {
  margin: 0 !important;
}

.me-1 {
  margin-right: 1rem !important;
}

.my-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.text-center {
  text-align: center;
}

.MuiDataGrid-cell {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.flex-start {
  display: flex !important;
  justify-content: start;
  align-items: center;
}

.w200 {
  width: 200px;
}

.noUnderlineLink {
  text-decoration: none;
}

.MuiDialog-root {
  z-index: 1501 !important;
}

.MuiDialog-root + .MuiPopover-root.MuiMenu-root.MuiModal-root {
  z-index: 1502 !important;
}

.MuiTabs-root button {
  text-transform: capitalize;
  color: rgb(73, 68, 64);
}

.welcome-banner {
  height: auto;
  margin-bottom: 0px;
  background-image: none;
  flex-direction: column-reverse !important;
}
@media (min-width: 900px) {
  .welcome-banner {
    height: 530px;
    margin-bottom: 100px;
    flex-direction: row !important;
    background-image: url("/src/img/welcome-banner.jpeg");
  }
}

.welcome-banner-left {
  background-color: rgba(0, 118, 206, 0.5);
  color: white;
  padding: 50px;
  font-size: 1.2em;
}
.welcome-banner-left p {
  line-height: 1.5;
  font-weight: 600;
}

.page-title {
  background: #62B5E5;
  margin-bottom: 50px;
  padding: 0 20px;
  position: relative;
}
.page-title h1 {
  background: none;
  color: #fff;
  font-weight: 600;
  display: block;
  margin-top: 0;
  line-height: 1;
  font-size: 2.2rem;
  padding: 40px 0 35px;
}

.page-title::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-width: 0 15px 15px;
  border-style: dashed dashed solid;
  border-color: transparent transparent #f8f8f8;
}

.featured-categories-grid .category {
  height: 140px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  background-size: cover;
}
.featured-categories-grid .category-title {
  background: rgba(0, 60, 105, 0.8);
  height: 45px;
  margin-top: auto;
}
.featured-categories-grid .category-title a {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  height: 100%;
}
.featured-categories-grid .category-title a span {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  text-transform: uppercase;
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.featured-categories-grid .category-title a span:nth-child(2) {
  white-space: nowrap;
}

.homepageImpactPrograms {
  background-image: url("/src/img/homepage-impact-programs.jpg");
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0px;
  gap: 100px;
  position: relative;
  width: 1200px;
  height: 350px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.homepageImpactProgramsOverlay {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 100%;
}

.homepageImpactProgramsLeft {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.homepageImpactProgramsRight {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.homepageImpactProgramsLogo {
  width: 300px;
  height: 101.66px;
  margin-top: 40px;
  margin-left: 40px;
}

.homepageImpactProgramsText {
  margin-top: 20px;
  margin-left: 40px;
  width: 350px;
  height: 60px;
  font-family: "Gotham-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  align-items: flex-end;
  color: #FFFFFF;
}

.homepageImpactProgramsButton {
  margin-top: 30px !important;
  margin-left: 40px !important;
  border: 1px solid white !important;
  color: white !important;
  border-radius: 5px;
  width: 300px;
  padding: 10px;
}

.homepageImpactProgramsRight {
  width: 552px;
  height: 350px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  gap: 12px;
}

@media only screen and (max-width: 1026px) {
  .homepageImpactProgramsRight {
    display: none;
  }
}
.homepageImpactProgramsRightItem {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.homepageImpactProgramsPlus {
  width: 20px;
  height: 20px;
}

.homepageImpactProgramsPlusText {
  font-family: "Gotham-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  color: #FFFFFF;
  margin-left: 10px;
}

.itemCountText {
  font-size: 0.8rem;
  color: #003B5C;
}

.tabSpecificationList {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.tabAboutList {
  line-height: 34px;
}

.flatProductSpecificationHeader {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
  font-weight: 700;
  font-size: 16px;
}

.autocompleteColorSelector {
  display: flex !important;
  justify-content: start;
  align-items: center;
  padding-left: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.bottomCardHolderText {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
.bottomCardHolderText p {
  font-size: 14px;
}

.MuiTabs-indicator {
  background-color: #62B5E5 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #62B5E5 !important;
}

section[data-autocomplete-source-id="categories.lvl1"] ul,
section[data-autocomplete-source-id=manufacturername] ul {
  display: flex;
}

.aa-SubmitIcon {
  color: #62b5e5 !important;
}

.sidebarIconLink span {
  display: flex;
  gap: 10px;
  font-weight: 500;
}
.sidebarIconLink span img {
  width: 20px;
  height: 20px;
}

.productDetailsSecondaryContainer {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

.productDetailsSecondaryImage {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  border: 1px solid white;
}
.productDetailsSecondaryImage img {
  width: 100%;
  max-height: 100%;
}
.productDetailsSecondaryImage:hover {
  border: 1px solid #eaeaea;
}

@media screen and (min-width: 1200px) and (max-width: 1620px) {
  .mainMenuButtonsResponsive {
    width: 125px;
    padding: 10px !important;
    font-size: 90% !important;
  }
}
.homepageFeatureZoneContainer .grid-1 {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.homepageFeatureZoneContainer .carouselContainer {
  height: 100%;
  background-color: #163b56;
  background-size: 35%;
  background-image: url("/src/img/featureZoneCarouselBackground.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  padding: 0px;
  box-sizing: border-box;
  color: white;
  border-radius: 15px;
  min-height: 290px;
}
@media (min-width: 900px) {
  .homepageFeatureZoneContainer .carouselContainer {
    background-size: 45%;
    min-height: 600px;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .homepageFeatureZoneContainer .carouselContainer {
    min-height: 450px;
  }
}
.homepageFeatureZoneContainer .carouselContainer h1 {
  font-size: 22px;
}
@media (min-width: 600px) {
  .homepageFeatureZoneContainer .carouselContainer h1 {
    font-size: 46px;
  }
}
.homepageFeatureZoneContainer .carouselContainer h2 {
  font-size: 16px;
}
@media (min-width: 600px) {
  .homepageFeatureZoneContainer .carouselContainer h2 {
    font-size: 26px;
  }
}
.homepageFeatureZoneContainer .slide {
  height: 100%;
  margin: 30px;
}
.homepageFeatureZoneContainer .slideBuilderWidget {
  height: 100%;
}
.homepageFeatureZoneContainer .carouselContainer button {
  z-index: 999999;
}
.homepageFeatureZoneContainer .carouselContainerSmall {
  background-color: #40C1AC;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
  text-align: center;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (min-width: 1024px) {
  .homepageFeatureZoneContainer .carouselContainerSmall {
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .homepageFeatureZoneContainer .carouselContainerSmall {
    padding-bottom: 0px;
  }
}
.homepageFeatureZoneContainer .carouselContainerSmall img {
  max-width: 80%;
  width: 40%;
}
@media (min-width: 1024px) {
  .homepageFeatureZoneContainer .carouselContainerSmall img {
    width: 100%;
  }
}
.homepageFeatureZoneContainer .carouselContainerSmall .linkContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 40px;
}
.homepageFeatureZoneContainer .carouselContainer div:first-child {
  height: 90% !important;
}
.homepageFeatureZoneContainer .mapHolder {
  overflow: hidden;
  border-radius: 15px !important;
  width: 100%;
  height: 350px;
}
@media (min-width: 600px) {
  .homepageFeatureZoneContainer .mapHolder {
    height: 600px;
  }
}
.homepageFeatureZoneContainer .branch {
  position: absolute;
  z-index: 1;
  width: 97%;
  height: 96%;
  padding: 10px 25px;
  box-sizing: border-box;
  background: linear-gradient(90deg, rgb(44, 126, 172) 0%, rgba(98, 181, 229, 0.7) 100%);
  border-radius: 15px;
  color: white;
}
@media (min-width: 600px) {
  .homepageFeatureZoneContainer .branch {
    width: 50%;
    height: 98%;
  }
}
@media (min-width: 1024px) {
  .homepageFeatureZoneContainer .branch {
    width: 50%;
    height: 98%;
  }
}
@media (min-width: 1200px) {
  .homepageFeatureZoneContainer .branch {
    width: 50%;
    height: 97.5%;
  }
}
.homepageFeatureZoneContainer .branchText {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.homepageFeatureZoneContainer .branchText h1 {
  font-size: 22px;
}
@media (min-width: 600px) {
  .homepageFeatureZoneContainer .branchText h1 {
    font-size: 28px;
  }
}
.homepageFeatureZoneContainer .branchText .branchTextNormal {
  font-weight: 400;
}
.homepageFeatureZoneContainer h2 {
  margin-top: 0;
  font-weight: normal;
}
.homepageFeatureZoneContainer .carouselContainerSmall {
  color: white;
}
.homepageFeatureZoneContainer .carouselContainerSmall h2 {
  margin-top: 20px;
  margin-bottom: 0;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  padding: 0 5px;
}
.homepageFeatureZoneContainer .carouselContainerSmall .animation {
  align-self: center;
}
.homepageFeatureZoneContainer .grid-1 {
  order: 1;
}
.homepageFeatureZoneContainer .grid-2 {
  order: 3;
}
.homepageFeatureZoneContainer .grid-3 {
  order: 2;
  margin-bottom: 10px;
}
@media (min-width: 1200px) {
  .homepageFeatureZoneContainer .grid-1 {
    order: 1;
  }
  .homepageFeatureZoneContainer .grid-2 {
    order: 2;
  }
  .homepageFeatureZoneContainer .grid-3 {
    order: 3;
    margin-bottom: 0px;
  }
}

.gallerySlideshow {
  margin-bottom: 50px;
}
.gallerySlideshow .gallerySlides img {
  width: 100%;
  height: 100%;
  border: 1px solid #ebebeb;
  box-shadow: 0 0 5px #ccc;
  box-sizing: content-box;
}

.dialogFullsizeImage {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 50px);
}

.fullsizeTablet {
  width: 100%;
}

.measureContainer {
  white-space: wrap;
  display: flex;
  gap: 5px;
  font-weight: 600;
  font-size: 14px;
}

.hide-xs {
  display: block;
}
@media (min-width: 900px) {
  .hide-xs {
    display: none;
  }
}

.hide-on-mob {
  display: none;
}
@media (min-width: 900px) {
  .hide-on-mob {
    display: block;
  }
}

.responsiveMarker-hide {
  position: fixed;
  top: 0px;
  height: 15px;
  z-index: 99999 !important;
  width: 100%;
  font-size: 10px;
}
@media (min-width: 600px) {
  .responsiveMarker-hide {
    background: lime;
  }
}
@media (min-width: 900px) {
  .responsiveMarker-hide {
    background: blue;
  }
}
@media (min-width: 1024px) {
  .responsiveMarker-hide {
    background: pink;
  }
}
@media (min-width: 1200px) {
  .responsiveMarker-hide {
    background: gray;
  }
}

.handCursor {
  cursor: pointer;
}

.cp_backgroundImage {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 320px;
  background-position-x: 50%;
  background-position-y: 50%;
}
.cp_backgroundImage h1 {
  color: white;
  font-size: 46px;
  text-align: left;
  width: 100%;
  font-weight: 600;
}

.customerProgramsTable {
  border-collapse: collapse;
  max-width: 350px;
}
.customerProgramsTable th {
  text-align: center;
  background-color: #62B5E5;
  color: white;
  border: 1px solid #ddd;
  padding: 10px;
}
.customerProgramsTable td {
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
}

.aa-Autocomplete .aa-DetachedSearchButtonPlaceholder {
  text-transform: uppercase !important;
}

.billPay {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 20px;
  padding: 30px 0;
}

.carouselContainerSmallLogged {
  background-color: #40C1AC;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.view-now-btn {
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-transform: none;
  border-radius: 4px;
  font-weight: 500;
  background: #ffffff;
  color: black;
  text-decoration: none;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 20px;
}

.mb-5perc {
  margin-bottom: 5%;
}

.text-white {
  color: white;
}

@media screen and (max-width: 1199px) {
  .mb-5perc {
    margin-bottom: 20px;
  }
  .mb-5percLast {
    margin-bottom: 50px !important;
  }
  .billPay {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
#root {
  overflow-x: hidden !important;
}

.cartHeader {
  margin: 0 -15px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  padding: 0 15px 2px 15px;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.cartCloseButton {
  min-width: 25px !important;
  height: 25px;
  margin: -4px -10px 0 0 !important;
}

.cardHolder {
  max-height: 360px;
  position: relative;
  margin: 2px -15px;
  padding: 0 15px;
  overflow-y: scroll;
  background-color: #fff;
}

.cartItemsBox {
  margin-top: 16px !important;
}

.cartFooter {
  margin: 0 -15px -15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background-color: #eaeaea;
  box-shadow: 0 0 8px 0 gray;
}
.cartFooter .blueButton {
  margin-top: 10px;
  padding: 12px 30px;
  border: 0;
}

.itemCartContainer {
  display: flex;
  align-items: top;
  margin-bottom: 15px;
}

.itemCartImage {
  width: 90px;
  height: 90px;
  overflow: hidden;
  min-width: 90px;
  margin-right: 10px;
}
.itemCartImage img {
  width: 100%;
}

.itemCartDescHeader {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.itemCartDescName {
  overflow: hidden;
  max-height: 46px;
  font-size: 18px;
  line-height: 22px;
  font-family: "DroidSerif-Regular", Times New Roman, serif;
  padding-right: 36px;
  color: #000;
  text-decoration: none;
}

.itemCartDesc h3 {
  font-size: 16px;
  line-height: 22px;
  font-family: "DroidSerif700";
  font-weight: 100;
  margin: 0 !important;
}
.itemCartDesc table tr .color-gray {
  color: #494440;
}
.itemCartDesc table tr td {
  padding: 1px 0;
  font-size: 13px;
  border: 0;
}
.itemCartDesc table tr td .color-green {
  color: #008000;
}
.itemCartDesc table tr td .cart-item-special {
  color: red;
}
.itemCartDesc table tr td:nth-child(2) {
  font-weight: 500;
}
.itemCartDesc table tr td input {
  padding: 0;
  text-align: center;
  width: 55px;
}
.itemCartDesc table tr td input::-webkit-outer-spin-button,
.itemCartDesc table tr td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.itemCartDesc table tr td input[type=number] {
  -moz-appearance: textfield;
}
.itemCartDesc table tr td fieldset {
  border: 0;
}
.itemCartDesc .w90 {
  width: 140px;
}

.fontSize14 {
  font-size: 14px;
}

.itemCardClose {
  position: absolute;
  top: 0px;
  right: -5px;
}

.zIndex2000 {
  z-index: 2000 !important;
}

.secondaryUserBanner {
  text-align: center;
  background: #62b5e5;
  color: white;
  padding: 10px;
}

.mobHeightBox {
  height: 250px !important;
}
@media (min-width: 900px) {
  .mobHeightBox {
    height: 42% !important;
  }
}

.homepageFeatureZoneContainer .grid-2 {
  order: 2;
}
@media (min-width: 900px) {
  .homepageFeatureZoneContainer .grid-2 {
    order: 3;
  }
}/*# sourceMappingURL=App.css.map */