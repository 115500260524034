$breakpoints: (
  'small': 600px,
  'medium': 900px,
  'large': 1024px,
  'xlarge': 1200px
);

// Responsive
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $size: map-get($breakpoints, $breakpoint);

    @media (min-width: $size) {
      @content;
    }
  }

  @else {
    @warn "Breakpoint '#{$breakpoint}'";
  }
}



@mixin center-flex {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@mixin start-flex {
  display: flex !important;
  justify-content: start;
  align-items: center;
}

@mixin spaceBetween-flex {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

@mixin search-icon {
  background-image: url("/src/img/search.svg");
  background-position: 8px;
  background-size: 20px;
  background-repeat: no-repeat;
}

@mixin view-all-link {
  font-weight: normal !important;
  font-style: italic !important;
  text-decoration: underline !important;
  color: black !important
}