@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tienne:wght@400;700;900&display=swap');

body {
  font-family: $defaultFont;
  margin: 0 auto;
  color: $bodyColorText;
  font-weight: 400;
  font-optical-sizing: auto;
  font-style: normal;

}

.customerProgramsFont{
  font-family: $defaultFont;
  font-weight: 400;
  font-style: normal;
  line-height: 26px;
}
.customerProgramsFontBold{
  font-family: $defaultFont;
  font-weight: 700;
  font-style: normal;
  line-height: 26px;
}
.customerProgramsFontBlack{
  font-family: $defaultFont;
  font-weight: 900;
  font-style: normal;
  line-height: 26px;
}
