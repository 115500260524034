a {
  color: $lansingBlue
}

.whiteButton {
  border: 1.75px solid #fff;
  border-radius: 4px !important;
  color: #fff;
  padding: 15px 30px;
  margin-top: 20px;
  display: inline-block !important;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    background: white;
    color: $lansingBlue;
  }
}

.blueButton {
  border: 1.75px solid #fff;
  border-radius: 4px !important;
  background: $lansingBlue;
  color: #fff;
  padding: 15px 30px;
  margin-top: 20px;
  display: inline-block !important;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    background: white;
    color: $lansingBlue;
    border: 1.75px solid $lansingBlue;
  }
}

.blueButtonOutline {
  border: 1.75px solid $lansingBlue !important;
  border-radius: 4px !important;
  background: white;
  color: $lansingBlue !important;
  padding: 15px 30px;
  margin-top: 20px;
  display: inline-block !important;
  font-size: 15px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  font-family: $defaultFont;

  &:hover {
    background: $lansingBlue !important;
    color: white !important;
    border: 1.75px solid $lansingBlue !important;
  }
}

.linkButtons {
  font-family: "Montserrat";
  color: "red"
}

.mainMenuButtons {
  text-decoration: none;
  color: #494440 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 21px !important;
  border-radius: 0 !important;

  &:hover {
    background-color: #494440 !important;
    color: white !important;
  }

  &:focus {
    background-color: #494440 !important;
    color: white !important;
  }
}

.button-active {
  background: #494440 !important;
  color: white !important;
}

.carouselBackButton {
  position: absolute !important;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 40px;
  min-width: 40px !important;
  border-radius: 30px !important;
  color: white !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  svg {
    position: relative;
    left: 5px
  }
}

.carouselNextButton {
  position: absolute !important;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6) !important;
  height: 40px;
  min-width: 40px !important;
  border-radius: 30px !important;
  color: white !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }

  svg {
    position: relative;
  }
}