@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
.builder-page-width {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  font-size: 16px;
  font-family: var(--sans-heading, Gotham-Medium, Montserrat);
}

.builderiotext h1,
.builderiotext h2,
.builderiotext h3,
.builderiotext h4,
.builderiotext h5,
.builderiotext h6,
.builderiotext p,
.builder-text h1,
.builder-text h2,
.builder-text h3,
.builder-text h4,
.builder-text h5,
.builder-text h6,
.builder-text p {
  color: var(--text-primary, #555555);
  font-feature-settings: "liga" off, "clig" off;
  font-family: var(--sans-heading, Gotham-Medium, Montserrat);
  font-style: normal;
  margin: 0;
}
.builderiotext p strong,
.builder-text p strong {
  font-weight: 900;
  color: var(--text-primary, #555555);
}

.builderiotext h1,
.builder-text h1 {
  font-size: 96px;
}
@media (max-width: 768px) {
  .builderiotext.fontSize100 > h1,
  .builder-text.fontSize100 > h1 {
    font-size: 96px;
  }
  .builderiotext.fontSize90 > h1,
  .builder-text.fontSize90 > h1 {
    font-size: 86.4px;
  }
  .builderiotext.fontSize80 > h2,
  .builder-text.fontSize80 > h2 {
    font-size: 76.8px;
  }
  .builderiotext.fontSize70 > h1,
  .builder-text.fontSize70 > h1 {
    font-size: 67.2px;
  }
  .builderiotext.fontSize60 > h1,
  .builder-text.fontSize60 > h1 {
    font-size: 57.6px;
  }
  .builderiotext.fontSize50 > h1,
  .builder-text.fontSize50 > h1 {
    font-size: 48px;
  }
}

.builderiotext h1,
.builder-text h1 {
  font-weight: 400;
  line-height: 116.7%;
  letter-spacing: -1.5px;
}
.builderiotext h1 em,
.builder-text h1 em {
  font-family: var(--sans-body, DroidSerif-Italic);
}

.builderiotext h2,
.builder-text h2 {
  font-size: 60px;
}
@media (max-width: 768px) {
  .builderiotext.fontSize100 > h2,
  .builder-text.fontSize100 > h2 {
    font-size: 60px;
  }
  .builderiotext.fontSize90 > h2,
  .builder-text.fontSize90 > h2 {
    font-size: 54px;
  }
  .builderiotext.fontSize80 > h2,
  .builder-text.fontSize80 > h2 {
    font-size: 48px;
  }
  .builderiotext.fontSize70 > h2,
  .builder-text.fontSize70 > h2 {
    font-size: 42px;
  }
  .builderiotext.fontSize60 > h2,
  .builder-text.fontSize60 > h2 {
    font-size: 36px;
  }
  .builderiotext.fontSize50 > h2,
  .builder-text.fontSize50 > h2 {
    font-size: 30px;
  }
}

.builderiotext h2,
.builder-text h2 {
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.5px;
}
.builderiotext h2 em,
.builder-text h2 em {
  font-family: var(--sans-body, DroidSerif-Italic);
}

.builderiotext h3,
.builder-text h3 {
  font-size: 48px;
}
@media (max-width: 768px) {
  .builderiotext.fontSize100 > h3,
  .builder-text.fontSize100 > h3 {
    font-size: 48px;
  }
  .builderiotext.fontSize90 > h3,
  .builder-text.fontSize90 > h3 {
    font-size: 43.2px;
  }
  .builderiotext.fontSize80 > h3,
  .builder-text.fontSize80 > h3 {
    font-size: 38.4px;
  }
  .builderiotext.fontSize70 > h3,
  .builder-text.fontSize70 > h3 {
    font-size: 33.6px;
  }
  .builderiotext.fontSize60 > h3,
  .builder-text.fontSize60 > h3 {
    font-size: 28.8px;
  }
  .builderiotext.fontSize50 > h3,
  .builder-text.fontSize50 > h3 {
    font-size: 24px;
  }
}

.builderiotext h3,
.builder-text h3 {
  line-height: 116.7%;
  letter-spacing: -0.7px;
  font-weight: 400;
}
.builderiotext h3 strong,
.builder-text h3 strong {
  font-weight: 700;
  color: var(--text-primary, #555555);
}
.builderiotext h3 em,
.builder-text h3 em {
  font-family: var(--sans-body, DroidSerif-Italic);
}

.builderiotext h4,
.builder-text h4 {
  font-size: 34px;
}
@media (max-width: 768px) {
  .builderiotext.fontSize100 > h4,
  .builder-text.fontSize100 > h4 {
    font-size: 34px;
  }
  .builderiotext.fontSize90 > h4,
  .builder-text.fontSize90 > h4 {
    font-size: 30.6px;
  }
  .builderiotext.fontSize80 > h4,
  .builder-text.fontSize80 > h4 {
    font-size: 27.2px;
  }
  .builderiotext.fontSize70 > h4,
  .builder-text.fontSize70 > h4 {
    font-size: 23.8px;
  }
  .builderiotext.fontSize60 > h4,
  .builder-text.fontSize60 > h4 {
    font-size: 20.4px;
  }
  .builderiotext.fontSize50 > h4,
  .builder-text.fontSize50 > h4 {
    font-size: 17px;
  }
}

.builderiotext h4,
.builder-text h4 {
  font-weight: 400;
  line-height: 123.5%;
  letter-spacing: -0.7px;
}
.builderiotext h4 strong,
.builder-text h4 strong {
  font-weight: 700;
  color: var(--text-primary, #555555);
}
.builderiotext h4 em,
.builder-text h4 em {
  font-family: var(--sans-body, DroidSerif-Italic);
}

.builderiotext h5,
.builder-text h5 {
  font-size: 24px;
}
@media (max-width: 768px) {
  .builderiotext.fontSize100 > h5,
  .builder-text.fontSize100 > h5 {
    font-size: 24px;
  }
  .builderiotext.fontSize90 > h5,
  .builder-text.fontSize90 > h5 {
    font-size: 21.6px;
  }
  .builderiotext.fontSize80 > h5,
  .builder-text.fontSize80 > h5 {
    font-size: 19.2px;
  }
  .builderiotext.fontSize70 > h5,
  .builder-text.fontSize70 > h5 {
    font-size: 16.8px;
  }
  .builderiotext.fontSize60 > h5,
  .builder-text.fontSize60 > h5 {
    font-size: 14.4px;
  }
  .builderiotext.fontSize50 > h5,
  .builder-text.fontSize50 > h5 {
    font-size: 12px;
  }
}

.builderiotext h5,
.builder-text h5 {
  font-weight: 400;
  line-height: 133.4%;
  letter-spacing: -0.7px;
}
.builderiotext h5 strong,
.builder-text h5 strong {
  font-weight: 500;
  color: var(--text-primary, #555555);
}
.builderiotext h5 em,
.builder-text h5 em {
  font-family: var(--sans-body, DroidSerif-Italic);
}

.builderiotext h6,
.builder-text h6 {
  font-size: 20px;
}
@media (max-width: 768px) {
  .builderiotext.fontSize100 > h6,
  .builder-text.fontSize100 > h6 {
    font-size: 20px;
  }
  .builderiotext.fontSize90 > h6,
  .builder-text.fontSize90 > h6 {
    font-size: 18px;
  }
  .builderiotext.fontSize80 > h6,
  .builder-text.fontSize80 > h6 {
    font-size: 16px;
  }
  .builderiotext.fontSize70 > h6,
  .builder-text.fontSize70 > h6 {
    font-size: 14px;
  }
  .builderiotext.fontSize60 > h6,
  .builder-text.fontSize60 > h6 {
    font-size: 12px;
  }
  .builderiotext.fontSize50 > h6,
  .builder-text.fontSize50 > h6 {
    font-size: 10px;
  }
}

.builderiotext h6,
.builder-text h6 {
  font-family: var(--sans-heading, Gotham-Medium);
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
}
.builderiotext h6 em,
.builder-text h6 em {
  font-family: var(--sans-body, DroidSerif-Italic);
}

.builderiotext p,
.builder-text p {
  font-size: 16px;
}
@media (max-width: 768px) {
  .builderiotext.fontSize100 > p,
  .builder-text.fontSize100 > p {
    font-size: 16px;
  }
  .builderiotext.fontSize90 > p,
  .builder-text.fontSize90 > p {
    font-size: 14.4px;
  }
  .builderiotext.fontSize80 > p,
  .builder-text.fontSize80 > p {
    font-size: 12.8px;
  }
  .builderiotext.fontSize70 > p,
  .builder-text.fontSize70 > p {
    font-size: 11.2px;
  }
  .builderiotext.fontSize60 > p,
  .builder-text.fontSize60 > p {
    font-size: 9.6px;
  }
  .builderiotext.fontSize50 > p,
  .builder-text.fontSize50 > p {
    font-size: 8px;
  }
}

.builderiotext p,
.builderiotext p strong,
.builderiotext p em,
.builder-text p,
.builder-text p strong,
.builder-text p em {
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: "liga" off, "clig" off;
  font-family: var(--sans-body, Gotham-Medium);
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.15px;
  font-family: var(--sans-heading, Gotham-Medium, Montserrat);
  color: var(--text-primary, #555555);
}

@media (max-width: 992px) {
  .builder-page-width {
    padding: 0 !important;
    margin-top: -55px !important;
  }
}
@media only screen and (min-width: 640px) and (max-width: 1200px) {
  .builder-page-width {
    padding: 0px !important;
    width: 97%;
    margin-top: -40px !important;
  }
  .carouselContainerSmall {
    align-items: center;
  }
  .carouselContainerSmall .linkContainer {
    padding: 0 !important;
    margin: 90px 0 0 0 !important;
    gap: 20px;
  }
}
@media screen and (max-width: 680px) {
  .aa-Panel {
    top: 0px !important;
  }
}
.ais-NavMenu-span {
  word-wrap: break-word;
  white-space: normal;
}/*# sourceMappingURL=AppExtensions.css.map */